#bn_quickrequest_horizontal2
{
    .bn-form-quickrequest-container {
        .bn-iconBtn {
            border-radius: 0;
            color: #A38F85 !important;
            background: #fff;
            font-size: 0.7em;
            &:hover {
                color: #fff !important;
                background: #A38F85 !important;
            }
        }
        .bn-form__submit > button {
            border: none;
            &:hover {
                background: #fff !important;
                color: #832C68!important;
            }
        }
    }
}
#bn_quickrequest .has-error .bn-form__groupIcon,
#bn_quickrequest .bn-form__group.has-error .bn-form__label {
    color: #ab0707!important;
}
#bn_quickrequest .bn-form__group.has-error input {
    border-bottom: 1px solid #ab0707!important;
    color: #ab0707!important;
}


#bn_quickrequest .bn-form__group.bn-valid input {
    border-bottom: 1px solid #008000!important;
    color: #008000!important;
}
#bn_quickrequest .bn-valid .bn-form__groupIcon,
#bn_quickrequest .bn-form__group.bn-valid .bn-form__label {
    color: #008000!important;
}


#bn_newsletter .bn-form__group.bn-valid input {
    border-bottom: 1px solid #008000!important;
    color: #008000!important;
}
#bn_newsletter .bn-valid .bn-form__groupIcon,
#bn_newsletter .bn-form__group.bn-valid .bn-form__label {
    color: #008000!important;
}


#bn_request .bn-form__group.bn-valid input {
    border-bottom: 1px solid #008000!important;
    color: #008000!important;
}
#bn_request .bn-valid .bn-form__groupIcon,
#bn_request .bn-form__group.bn-valid .bn-form__label {
    color: #008000!important;
}
#bn_request .bn-form__checkbox label:hover .bn-form__helper {
  color: #832C68 !important;
}

#bn_newsletter {
    .bn-form.bn-form-newsletter {
        .bn-form__submit > button {
            border: solid 1px #fff !important;
            color: #fff !important;
            &:hover {
                color: #8E877F !important;
                border-color: #8E877F !important;
            }
        }
    }
}

.bn_request.detail,
.offer_popup {
    .ractive--offer-lastminute-Template {
        display: none;
    }
}

.flatpickr-calendar.open {
    z-index: 200 !important;
}
