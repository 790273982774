/*  ==========================================================================
    HEADER V3
    ========================================================================== */

@mixin header--v3() {
    background: linear-gradient(rgba($clr__black, 0.4), transparent);
    left: 0;
    min-height: $unit__hgt--header-v3;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    // controls
    .bn-controls {
        color: $clr__white;
        .bn-controls__left,
        .bn-controls__center,
        .bn-controls__right {
            padding: 40px 0 40px 0;
        }
        .bn-controls__center {
            text-align: center;
            max-width: 280px;
        }
        .bn-controls__right {
            font-size: 2em;
            text-align: right;
            a {
                color: $clr__white;
                &:visited {
                    color: $clr__white;
                }
                &:hover {
                    color: $clr__white;
                    text-decoration: underline;
                }
            }
        }
    }
    .bn-main-menu {
        .bn-main-menu__lvl--0 {
            > .bn-main-menu__item {
                .bn-main-menu__sub-menu {
                    border-top-color: transparent;
                    text-shadow: none;
                    > .bn-main-menu__arrow {
                        &:before,
                        &:after {
                            border-top: 1px solid $clr__white;
                        }
                    }

                    .bn-main-menu__item--act,
                    .bn-main-menu__item--cur {
                        > .bn-main-menu__link {
                            font-weight: $typo__fw--bold;
                        }
                    }
                }
                &.bn-main-menu__item--act,
                &.bn-main-menu__item--cur {
                    > .bn-main-menu__link {
                        font-weight: $typo__fw--bold;
                    }
                }
            }
        }
    }
    // burger menu
    .bn-burger-menu {
        cursor: pointer;
        display: inline-block;
        pointer-events: initial;
        .bn-icon {
            font-size: 5em;
        }
        &.bn-sidebar-trigger--act {
            display: none;
        }
        &:hover {
            color: $clr__ci--primary;
        }
    }
    // sidebar
    .bn-sidebar {
        @include header--sidebar();
        pointer-events: initial;
    }
    // main logo
    .bn-main-logo {
        &.bn-main-logo--above {
            display: inline-block;
            pointer-events: initial;
            .bn-main-logo__image {
                img {
                    @include img--rendering-crisp-edges();
                    // max-width: 240px;
                    height: 146px;
                }
                &.bn-main-logo__image--small {
                    display: none;
                }
                &.bn-main-logo__image--mobile {
                    display: none;
                }
            }
        }
    }

    //add arrow to menu with submenu
    .bn-main-menu__item--has-children .bn-main-menu__title::after {
        content: '\e902';
        font-family: 'bn-icons' !important;
        font-size: 0.8em;
        margin-left: 5px;
    }

    // tel
    .bn-tel {
        &.bn-tel--above {
            display: inline-block;
            height: $unit__hgt--header-v3_tel;
            line-height: $unit__hgt--header-v3_tel;
            pointer-events: initial;
            white-space: nowrap;
            .break {
                display: none;
            }
        }
    }
    // no vista
    &.bn-header--no-vista {
        &:not(.bn-header--minimized) {
            // main logo
            .bn-main-logo {
                &.bn-main-logo--above {
                    .bn-main-logo__image {
                        img {
                            height: 60px;
                        }
                    }
                }
            }
        }
    }
    // minimized
    &.bn-header--minimized {
        // controls
        .bn-controls {
            .bn-controls__center,
            .bn-controls__right {
                display: none;
            }
        }
    }

    /*
   * RESPONSIVE BEHAVIOR
   */

    // full screen on
    @media #{$mq__full-screen--on} {
        position: fixed;
    }
    // laptop
    @media #{$mq__laptop} {
        // controls
        .bn-controls {
            .bn-controls__center {
                max-width: 200px;
            }
        }
        // main logo
        .bn-main-logo {
            &.bn-main-logo--above {
                .bn-main-logo__image {
                    img {
                        // max-width: 160px;
                        height: 98px;
                    }
                }
            }
        }
    }
}
