/*  ==========================================================================
    COLORS
    List of all colors concerning your project.

    INFO:
    - change your project related colors here
    - if needed change also the specific colors
    - don't delete colors, only add (if possible here: custom)!
    - for naming help of additional custom colors use
      "http://chir.ag/projects/name-that-color/#000000"
    ========================================================================== */

/*
 * GENERAL
 */

$clr__black: #000000;
$clr__bn-red: #AE173B;
$clr__gray: #808080;
$clr__white: #FFFFFF;

/*
 * PROJECT RELATED
 */

// corporate identity
$clr__ci--primary: #C1BDB9;
$clr__ci--secondary: #A38F85;
$clr__ci--tertiary: #F3F2F1;

// typography
$clr__typo--primary: #626262;
$clr__typo--secondary: $clr__white;

// call to action
$clr__cta--primary: #fff;

// notification
$clr__correct: #008000;
$clr__error: $clr__ci--secondary;

/*
 * SPECIFIC
 */

$clr__page: $clr__white;
$clr__progress-bar: $clr__ci--secondary;
$clr__scrollbar: $clr__ci--primary;
$clr__selection: $clr__ci--secondary;

/*
 * CUSTOM
 */

