/*  ==========================================================================
    CONTROL BAR
    ========================================================================== */

@mixin mobile-header--control-bar() {
  background-color: rgba($clr__black, 0.8);
  color: $clr__white;
  cursor: pointer;
  display: none;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $unit__zi--mobile-header-control-bar;
  a {
    color: rgba($clr__white, 0.5);
    &:visited {
      color: rgba($clr__white, 0.5);
    }
    &:hover {
      color: $clr__white;
    }
  }
  .bn-control-bar__top,
  .bn-control-bar__center,
  .bn-control-bar__bottom {
    background-color: $clr__white;
    cursor: auto;
    display: flex;
    margin: 0;
    width: $unit__wdt--mobile-header-control-bar;
  }
  .bn-control-bar__top,
  .bn-control-bar__bottom {
    flex: none;
  }
  .bn-control-bar__top {
    > .bn-grid__col {
      @include clearfix();
      background-color: $clr__ci--primary;
    }
  }
  .bn-control-bar__center {
    @include scrollbar($clr__scrollbar,5px);
    flex: 1;
    overflow-y: auto;
  }
  .bn-control-bar__bottom {
    > .bn-grid__col {
      @include clearfix();
      background-color: rgba($clr__ci--primary, 0.8);
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--aside {
      display: inline-block;
      float: left;
      ul {
        @include clearfix();
        font-size: 1.6em;
        list-style-type: none;
        margin-left: -5px;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '|';
          }
          a {
            padding: 0 5px;
          }
          &.bn-lang-menu__item--act {
            a {
              color: $clr__white;
              font-weight: $typo__fw--bold;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  // close
  .bn-close {
    color: rgba($clr__white, 0.5);
    cursor: pointer;
    display: inline-block;
    float: right;
    .bn-icon {
      font-size: 2.5em;
    }
    &:hover {
      color: $clr__white;
    }
  }
  // main menu
  .bn-main-menu {
    background-color: rgba($clr__ci--primary, 0.9);
    min-height: 100%;
    padding: 10px 0;
    .bn-main-menu__lvl--0 {
      padding-left: 0;
      list-style-type: none;
      .bn-main-menu__item {
        &.bn-main-menu__item--act,
        &.bn-main-menu__item--cur {
          > .bn-main-menu__link {
            color: $clr__white;
          }
        }
      }
      > .bn-main-menu__item {
        > .bn-main-menu__link {
          display: block;
          padding: 10px 70px 10px 20px;
          position: relative;
          > .bn-main-menu__titles {
            .bn-main-menu__title,
            .bn-main-menu__subtitle {
              text-transform: uppercase;
            }
            .bn-main-menu__title {
              font-size: 2.0em;
            }
            .bn-main-menu__subtitle {
              font-size: 1.6em;
            }
          }
          .bn-sub-menu-trigger {
            color: rgba($clr__white, 0.5);
            cursor: pointer;
            font-size: 2.5em;
            height: 30px;
            line-height: 30px;
            margin-top: -15px;
            position: absolute;
            right: 20px;
            text-align: right;
            top: 50%;
            width: 30px;
            z-index: 5;
            display: none;
            .bn-icon {
              &.bn-icon--arrow-top {
                display: none;
              }
            }
            &.bn-sub-menu-trigger--act {
              .bn-icon {
                &.bn-icon--arrow-bottom {
                  display: none;
                }
                &.bn-icon--arrow-top {
                  display: inline-block;
                }
              }
            }
            &:hover {
              color: $clr__white;
            }
          }
        }
        .bn-main-menu__sub-menu {
          border-bottom: 1px dashed $clr__white;
          border-top: 1px dashed $clr__white;
          display: none;
          padding: 0 20px;
          > .bn-main-menu__arrow {
            display: none;
          }
          .bn-main-menu__lvl-wrap {
            .bn-main-menu__lvl {
              list-style-type: none;
              padding-left: 0;
              .bn-main-menu__item {
                .bn-main-menu__link {
                  font-size: 1.6em;
                  display: block;
                  padding: 5px 0;
                }
              }
            }
            &.bn-main-menu__lvl-wrap--1 {
              padding: 5px 0;
            }
            &.bn-main-menu__lvl-wrap--2 {
              padding: 5px 0;
              .bn-main-menu__item {
                position: relative;
                &:before {
                  content: '-';
                  display: block;
                  font-size: 1.6em;
                  left: 0;
                  position: absolute;
                  text-align: center;
                  top: 5px;
                  width: 20px;
                }
                .bn-main-menu__link {
                  padding-left: 20px;
                }
              }
            }
          }
          &.bn-main-menu__sub-menu--open {
            display: block;
          }
        }
        &.bn-main-menu__item--last {
          .bn-main-menu__sub-menu {
            border-bottom: 0;
          }
        }
      }
      > .bn-main-menu__item.bn-main-menu__item--has-children {
        .bn-sub-menu-trigger {
          display: block;
        }
      }
    }
  }
  // tel
  .bn-tel {
    &.bn-tel--aside {
      float: left;
      font-size: 1.6em;
      padding-right: 10px;
      width: 50%;
    }
  }
  // call to action
  .bn-call-to-action {
    &.bn-call-to-action--aside {
      float: right;
      padding-left: 10px;
      text-align: right;
      width: 50%;
      .bn-button {
        @include button--cta();
        max-width: 250px;
        min-width: 0;
        width: 100%;
      }
    }
  }
  &.bn-control-bar--open {
    display: flex;
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // phablet
  @media #{$mq__phablet} {
    // tel
    .bn-tel {
      &.bn-tel--aside {
        float: none;
        padding-right: 0;
        width: 100%;
      }
    }
    // call to action
    .bn-call-to-action {
      &.bn-call-to-action--aside {
        float: none;
        margin-top: 20px;
        padding-left: 0;
        text-align: left;
        width: 100%;
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    // lang menu
    .bn-lang-menu {
      &.bn-lang-menu--aside {
        ul {
          font-size: 1.4em;
        }
      }
    }
    // main menu
    .bn-main-menu {
      padding: 5px 0;
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          > .bn-main-menu__link {
            padding: 5px 50px 5px 10px;
            > .bn-main-menu__titles {
              .bn-main-menu__title {
                font-size: 2em;
              }
              .bn-main-menu__subtitle {
                font-size: 1.4em;
              }
            }
            .bn-sub-menu-trigger {
              right: 10px;
            }
          }
          .bn-main-menu__sub-menu {
            padding: 0 10px;
            .bn-main-menu__lvl-wrap {
              .bn-main-menu__lvl {
                .bn-main-menu__item {
                  .bn-main-menu__link {
                    font-size: 1.6em;
                  }
                }
              }
              &.bn-main-menu__lvl-wrap--2 {
                .bn-main-menu__item {
                  &:before {
                    font-size: 1.4em;
                  }
                }
              }
            }
          }
        }
      }
    }
    // tel
    .bn-tel {
      &.bn-tel--aside {
        font-size: 1.4em;
      }
    }
    // call to action
    .bn-call-to-action {
      &.bn-call-to-action--aside {
        margin-top: 10px;
      }
    }
  }
}