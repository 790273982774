/*  ==========================================================================
    BN GRID
    ========================================================================== */

// breakpoints
$bn-grid__bp--laptop: 1366px;
$bn-grid__bp--tablet: 1024px;
$bn-grid__bp--phablet: 768px;
$bn-grid__bp--phone: 480px;

// sizes
$bn-grid__size--s: 871px;
$bn-grid__size--m: 1470px;
$bn-grid__size--l: 1920px;

// spacing
$bn-grid__spacing--s: 20px;
$bn-grid__spacing--m: 40px;
$bn-grid__spacing--l: 80px;

// spacing tablet
$bn-grid__spacing-tablet--s: 10px;
$bn-grid__spacing-tablet--m: 20px;
$bn-grid__spacing-tablet--l: 40px;

// spacing phone
$bn-grid__spacing-phone--s: 5px;
$bn-grid__spacing-phone--m: 10px;
$bn-grid__spacing-phone--l: 20px;