/*  ==========================================================================
    REQUEST
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__request--bg: $clr__ci--primary;
$clr__form-background: #a38f85;
$clr__form-button-background: #832C68;
$clr__form-button-background-hover: #fff;
$clr__form-color: #fff;
/*
 * MODULE
 */

//.bn-request {
//  .bn-request__holder {
//    background-color: rgba($module-clr__request--bg, 0.1);
//    .bn_request_variation__vertical {
//      @include request--v1();
//    }
//  }
//}

/*CUSTOM-COLOR*/
#bn_request_vertical:not(.bn-dynformInit) > div:not(.bn-loader) {
  display: none!important;
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */