/*  ==========================================================================
    BUTTONS
    ========================================================================== */

// reset <button> tag
@mixin button--reset() {
  appearance: none;
  background: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
}

// button default
@mixin button--default() {
  background-color: $clr__ci--primary;
  border-color: $clr__ci--primary;
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  color: $clr__typo--secondary !important;
  cursor: pointer;
  display: inline-block;
  font-size: $typo__fs--button;
  height: 50px;
  line-height: 46px;
  padding: 0 20px;
  min-width: 250px;
  text-align: center;
  text-transform: uppercase;
  &.bn-button--small {
    height: 35px;
    line-height: 33px;
  }
  &:visited {
    color: $clr__typo--secondary !important;
  }
  &:hover {
    background-color: transparent;
    border-color: $clr__ci--primary;
    color: $clr__ci--primary !important;
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // phablet
  @media #{$mq__phablet} {
    &.bn-button--mobile {
      display: block;
      float: none;
      margin-top: 20px;
      min-width: 0;
      width: 100%;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  // phone
  @media #{$mq__phablet} {
    &.bn-button--mobile {
      margin-top: 10px;
    }
  }
}

// button default inverted
@mixin button--default-inverted() {
  @include button--default();
  background-color: transparent;
  border-color: $clr__ci--primary;
  color: $clr__ci--primary !important;
  &:visited {
    color: $clr__ci--primary !important;
  }
  &:hover {
    background-color: $clr__ci--primary;
    color: $clr__typo--secondary !important;
  }
}

// call to action button
@mixin button--cta($col: $clr__cta--primary, $hover: $clr__ci--secondary, $bg: "transparent", $border: $clr__cta--primary) {
  @include button--default();
  background-color: #{$bg};
  border-color: #{$border};
  color: #{$col} !important;
  &:visited {
    color: $col !important;
  }
  &:hover {
    background-color: transparent;
    border-color: #{$hover};
    color: #{$hover} !important;
  }
}

// call to action button inverted
@mixin button--cta-inverted() {
  @include button--default();
  background-color: transparent;
  border-color: $clr__cta--primary;
  color: $clr__cta--primary !important;
  &:visited {
    color: $clr__cta--primary !important;
  }
  &:hover {
    background-color: $clr__cta--primary;
    border-color: $clr__cta--primary;
    color: $clr__typo--secondary !important;
  }
}

// custom button
@mixin button--custom($clr-bg,$clr-border,$clr-font,$clr-bg-hover,$clr-border-hover,$clr-font-hover) {
  @include button--default();
  background-color: $clr-bg;
  border-color: $clr-border;
  color: $clr-font !important;
  &:visited {
    color: $clr-font !important;
  }
  &:hover {
    background-color: $clr-bg-hover;
    border-color: $clr-border-hover;
    color: $clr-font-hover !important;
  }
}

// button text
@mixin button--text($clr,$clr-hover) {
  color: $clr;
  display: inline-block;
  font-size: $typo__fs--button;
  letter-spacing: 1px;
  text-decoration: underline;
  text-transform: uppercase;
  &:visited {
    color: $clr;
  }
  &:hover {
    color: $clr-hover;
  }
}