/*  ==========================================================================
    QUICK REQUEST HORIZONTAL
    ========================================================================== */

@mixin quick-request--horizontal() {
    .bn_quickrequest:not(.bn-dynformInit) > div:not(.bn-loader) {
        display: none !important;
    }

    .bn_quickrequest {
        width: 100%;
    }
}